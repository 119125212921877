import merge from 'lodash/merge';
import pick from 'lodash/pick';
import set from 'lodash/set';
import values from 'lodash/values';
import moment from 'moment';
import { ATTENTION_URLS, TAG_KEYS, TYPE_NEED_LOG } from '../config';

const global = window?.global_data ?? {};

const contextInfo = {
  kdtId: global?.kdtId,
  customerNo: global?.userInfo?.customerNo,
};

const getModuleByUrl = () => {
  const pathname = window.location.pathname;

  const customerModule = ['/shopping-cart', '/me', '/reset-password'];
  const discountModule = ['/discount', '/gift', '/distribution', '/affiliate', '/group-buying', '/activity', '/member'];
  const payModule = ['/place-order', '/payment', ];
  const storeModule = ['/pages', '/recovery', '/close', '/callback'];
  const goodsModule = ['/collections', '/search', '/products'];
  const orderModule = ['/order'];
  const settingModule = ['/blogs', '/policy', '/form'];

  const getModule = (list) => list.some((path) => pathname.includes(path));

  if (getModule(customerModule)) {
    return 'Customer';
  } else if (getModule(discountModule)) {
    return 'Discount';
  } else if (getModule(payModule)) {
    return 'Pay';
  } else if (getModule(storeModule)) {
    return 'Store';
  } else if (getModule(goodsModule)) {
    return 'Goods';
  } else if (getModule(orderModule)) {
    return 'Order';
  } else if (getModule(settingModule)) {
    return 'Setting';
  } else {
    return 'Other';
  }
}

export const composeTag = (event, targetTag) => {
  if (!event.tags) {
    event.tags = {};
  }
  return merge(event.tags, targetTag);
};

export const composeUser = (event, targetUrl) => {
  if (!event.user) {
    event.user = {};
  }
  return merge(event.user, targetUrl);
};

export const setUserContext = (event) => {
  /**
   * 设置基础用户上下文
   * 提交 event 时会带上
   */
  composeUser(event, contextInfo);

  return event;
};

/** 重置 tags.url 为完整路径 */
export const setTagUrl = (event) => {
  composeTag(event, {
    url: window.location.href,
    module: getModuleByUrl(),
  });
  return event;
};

/** 设置店铺信息tag方便sentry搜索 */
export const setTagDept = (event) => {
  composeTag(event, contextInfo);
  return event;
};

/** 根据 url 判断是否需要增加tags.alert: send */
export const setTagsAlert = (event) => {
  const requestUrl = event.request.url;
  const isAttentioned = !!ATTENTION_URLS.find((url) => !!requestUrl.match(url));
  if (isAttentioned) {
    composeTag(event, {
      alert: 'send',
    });
  }
  return event;
};

/** 添加时间戳 */
export const setTagsTimeStr = (event) => {
  composeTag(event, {
    timeStr: moment().format('YYYY-MM-DD HH:mm:ss'),
  });
  return event;
};

/** 根据 url 增加tags.author */
export const setTagsAuthor = (event) => {
  // const requestUrl = event.request.url;

  return event;
};

/** 设置event 的 tags.route 可以根据 route 进行分类 */
export const setTagsRoute = (event) => {
  const requestUrl = event.request.url;
  const hash = requestUrl.split('#').slice(1)[0];
  if (!hash) {
    return event;
  }
  const route = hash.split('?').slice(0, 1)[0];
  if (!route) {
    return event;
  }
  composeTag(event, {
    route,
  });
  return event;
};

/** 预处理错误信息并捕获 */
export const setSearchMsg = (event) => {
  try {
    const newValues = event.exception.values.map((item) => {
      const tags = pick(event.tags, TAG_KEYS);
      const searchQuery = values(tags).join('-');

      return {
        ...item,
        value: `${typeof item.value === 'string' ? item.value : JSON.stringify(item.value)}; [Module: ${getModuleByUrl()}];`,
      };
    });

    set(event, 'exception.values', newValues);
  } catch (err) {
    console.error(`setSearchMsg失败${err.message}`);
  }
  return event;
};

/** 预处理错误信息并捕获 */
export const getErrorNeedLog = (event) => {
  const newValues = event?.exception?.values;
  // const newValues = event?.exception?.values?.filter(({ type }) => TYPE_NEED_LOG.indexOf(type) !== -1);

  set(event, 'exception.values', newValues);

  return event;
};

export const beforeSendHandler = (event) => {
  let errEvent = getErrorNeedLog(event);
  try {
    const needLogLen = event?.exception?.values?.length;

    if (needLogLen) {
      setTagUrl(event);
      setTagDept(event);
      setTagsAlert(event);
      setTagsRoute(event);
      setTagsTimeStr(event);
      setUserContext(event);
      setSearchMsg(event);
    } else {
      errEvent = null;
    }
  } catch (error) {
    console.error(error);
  } finally {
    return errEvent; // eslint-disable-line
  }
};
