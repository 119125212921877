import moment from 'moment';
import { init, captureException, withScope, Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';

import { beforeSendHandler } from './handler';

export const initSentry = () => {
  try {
    if (__isServer || window?.i18n_env?.APPLICATION_STANDARD_ENV !== 'prod') return;

    console.log('==========初始化sentry===========');

    const dsn = 'https://e2fc6846bce54290959cf927f044cb8d@crash.youzan.com/177';
    /** 统一线上环境变量值 */

    /** 使用打包时的时间作为 release 版本控制 */
    // eslint-disable-next-line no-undef
    const release = moment(__buildTime).format('YYYY-MM-DD HH:mm:ss');

    /** 初始化 传入配置 */
    init({
      dsn, // 识别
      release, // 发布版本
      environment: 'prod', // 环境
      beforeSend: beforeSendHandler, // 上报的钩子 附加信息
      ignoreErrors: [
        /Non-Error promise rejection captured/i,
        /crossDomain/i,
        /UnhandledRejection/i,
        /Load failed/i,
        /Loading CSS chunk/i,
        /Loading chunk (\S+) failed/i,
        /network is offline/i,
        /ServiceWorkerRegistration/i,
        /Failed to fetch/i,
        /timeout/i,
      ],
      allowUrls: [
        /\/\/intl-file\.yzcdn\.cn/
      ],
      whitelistUrls: [
        /\/\/intl-file\.yzcdn\.cn/
      ],
    });
    window.Sentry = Sentry;
    // 全局处理 Promise catch 回调事件
    // 解决过多未补充 catch 回调函数的 Promise 而产生的告警
    window.addEventListener('unhandledrejection', function browserRejectionHandler(event) {
      event && event.preventDefault()
    })
  } catch (error) {
    console.error('sentry 初始化失败');
  }
};

export const catchReqException = (err) => {
  withScope((scope) => {
    scope.setLevel(Severity.Warning);
    captureException(new Error(JSON.stringify(err)));
  });
};

/** 自定义上传sentry */
export const catchDebugException = (err) => {
  withScope((scope) => {
    scope.setLevel(Severity.Debug);
    captureException(new Error(JSON.stringify(err)));
  });
};

export default initSentry;
